import React,{Component} from 'react';
import Button from '@material-ui/core/Button';

class EmailButton extends Component {
    constructor(props){
        super(props);
        this.onClick = this.onClick.bind(this);
    }
    onClick(){
        window.location.href = `mailto:${this.props.email}`;
    }
    render(){
        return <Button variant="contained" color="primary"  onClick={this.onClick}>Email</Button>;
    }
}

export default EmailButton;