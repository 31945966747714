/* eslint indent: "error" */
import React from 'react'
import Typography from '@material-ui/core/Typography'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'

import { Helmet } from 'react-helmet'

import img_frezr from './assets/frezr.png'
import img_pioneer from './assets/pioneerdesigns.png'
import img_borp from './assets/borp.png'
import img_studiosatsanga from './assets/studiosatsanga.png'
import img_canvas from './assets/canvas2.png'
import img_bigned from './assets/bigned.gif'
import img_dirwin from './assets/dirwin.png'
import img_worldevents from './assets/worldevents.png'
import img_monoterm from './assets/monoterm.gif'
import img_pone from './assets/pone.png'
import img_searcher from './assets/searcher.png'
import img_libls from './assets/libls.png'
import img_mandel from './assets/mandel.png'
import img_deskdub from './assets/deskdub.gif'

import img_trigna from './assets/trigna.gif'
import img_airdriedayhome from './assets/airdriedayhome.png'
import img_wetbat from './assets/wetbat.png'

import './index.css'
import './app.scss'

import StyledCard from './StyledCard'
import TopButton from './TopButton'
import ContactInfo from './ContactInfo'
import EmailButton from './EmailButton'
import ResumeButton from './ResumeButton'
import MyNav from './MyNav'

const data = require('./data.json')

function Copyright () {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="http://pioneerdesigns.ca/">
        PioneerDesigns.ca
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: 30
    },
    introGrid: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(8),
        maxWidth: '56.25%'
    // background: 'linear-gradient(45deg, #0d559b 30%, #eee 90%)',
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8)
    // background: 'linear-gradient(45deg, #0d559b 30%, #eee 90%)',
    },
    cardGrid2: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8)
    // background: 'linear-gradient(45deg, #0d559b 30%, #eee 90%)',
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0
    },
    cardMedia: {
        paddingTop: '56.25%' // 16:9
    },
    cardContent: {
        flexGrow: 1
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6)
    },
    appbar: {
        minHeight: 40,
        maxHeight: 160,
        backgroundColor: theme.palette.background.paper
    }

}))

const cards = [1, 2, 3, 4, 5, 6, 7, 8]

export default function App () {
    const classes = useStyles()

    return (
        <React.Fragment>
            <CssBaseline />
            <Helmet>
                <title>PioneerDesigns.ca</title>
            </Helmet>

            <MyNav classes={classes}/>

            <main>
                <Divider />
                <section id="section-1">
                    <Container maxWidth="md" className={classes.introGrid}>
                        <div className="introholder">
                            <iframe src="../gumdist/index.html" frameBorder="0" className="gumframe"></iframe>

                            <ContactInfo classes={classes}/>
                        </div>
                    </Container>
                </section>
                <Divider />
                <section id="section-2">
                    <Container className={classes.cardGrid} maxWidth="md">
                        {/* End hero unit */}
                        <Grid container spacing={4}>

                            <Grid item xs={12} sm={6} md={6}>
                                <StyledCard
                                    classes={classes}
                                    img={img_frezr}
                                    heading="frezr.com"
                                    url="http://frezr.com"
                                    content="Personal website -- built using Python/Pelican -- blog posts are saved as .md then sent over git. Git hooks are used to regenerate the website."
                                    tech={['Python', 'Pelican', 'Git hooks']}/>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <StyledCard
                                    classes={classes}
                                    img={img_borp}
                                    heading="borp.us"
                                    url="https://borp.us"
                                    content="Data is fetched from multiple APIs. User authentication is done through Django and JWT. The fetched posts are then displayed with VueJS and rendered with Isotope to create a reactive grid layout.
                  All the components are running in isolated Docker instances. Log in with admin/admin."
                                    tech={['Python/Django', 'Docker', 'VueJS', 'Isotope']}/>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <StyledCard
                                    classes={classes}
                                    img={img_pioneer}
                                    heading="svg graph/slideshow"
                                    url="old/"
                                    content="A graph/slideshow demonstrating the architecture of borp.us. Using SVG draw paths and ScrollReveal to create a slide show."
                                    tech={['Javascript', 'SVG', 'ScrollReveal.js']}/>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <StyledCard
                                    classes={classes}
                                    img={img_wetbat}
                                    heading="wetbat"
                                    url="https://wetbat.herokuapp.com/admin/dashboard"
                                    content="A React application for managing Airline quotes -- MVP demo -- uses Sequelize as an ORM."
                                    tech={['ReactJS', 'NodeJS backend (express/feathersjs)', 'sequelize ORM', 'Heroku deployment']}/>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <StyledCard
                                    classes={classes}
                                    img={img_studiosatsanga}
                                    heading="studiosatsanga.ca"
                                    url="http://studiosatsanga.ca"
                                    content="A modern design for a yoga studio client, using a trivial parallax scrolling technique."
                                    tech={['HTML5', 'Javascript']}/>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <StyledCard
                                    classes={classes}
                                    img={img_airdriedayhome}
                                    heading="airdriedayhome.com"
                                    url="http://airdriedayhome.com"
                                    content="A simple design for a day care client."
                                    tech={['HTML5', 'Javascript']}/>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <StyledCard
                                    classes={classes}
                                    img={img_worldevents}
                                    heading="worldevents.live (under construction)"
                                    url="http://worldevents.live"
                                    content="World events are fetched from RSS feeds, stored and analyzed, then displayed on a world map."
                                    tech={['ReactJS', 'react-map-gl', 'redis', 'python']}/>
                            </Grid>

                        </Grid>

                    </Container>
                </section>

                <Divider />

                <section id="section-3">
                    <Container className={classes.cardGrid} maxWidth="md">
                        {/* End hero unit */}
                        <Grid container spacing={4}>

                            <Grid item xs={12} sm={6} md={6}>
                                <StyledCard
                                    classes={classes}
                                    img={img_canvas}
                                    heading="Canvas"
                                    url="http://frezr.com/javascript/canvas.html"
                                    content="Drawing cardioids of various degrees -- using HTML 5 canvas and pure javascript."
                                    tech={['Javascript', 'HTML5/Canvas']}/>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <StyledCard
                                    classes={classes}
                                    img={img_bigned}
                                    heading="Sid"
                                    url="https://github.com/gbdu/project_sid"
                                    content="Experiments with AI. Asynchronous processes running 64 components for processing audio, video, and textual information. The components are then weighted together and used to process information in real time."
                                    tech={['Python', 'pygame', 'pyconsole', 'multiprocessing']}/>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <StyledCard
                                    classes={classes}
                                    img={img_pone}
                                    heading="Pone"
                                    url="https://github.com/gbdu/pone"
                                    content="A basic game engine implemented from scratch -- with terrain generation, object loading, logging, and caching."
                                    tech={['C++', 'OpenGL 4', 'GLSL']}/>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <StyledCard
                                    classes={classes}
                                    img={img_libls}
                                    heading="libls"
                                    url="https://github.com/gbdu/libls"
                                    content="Type-agnostic linked list implementation. With features like foreach and data persistance. Demonstrating advanced knowledge of pointer math."
                                    tech={['C']}/>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <StyledCard
                                    classes={classes}
                                    img={img_mandel}
                                    heading="Mandelman"
                                    url="https://github.com/gbdu/mandelman"
                                    content="Simple Mandelbrot set browser with zoom feature."
                                    tech={['C', 'SDL']}/>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <StyledCard
                                    classes={classes}
                                    img={img_deskdub}
                                    heading="Deskdub"
                                    url="https://github.com/gbdu/deskdub"
                                    content="Audio spectrum analyzer. Implemented from scratch to demonstrate use of Fourier Transform."
                                    tech={['C++', 'GTK2']}/>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <StyledCard
                                    classes={classes}
                                    img={img_trigna}
                                    heading="Trigna"
                                    url="https://github.com/gbdu/Trigna"
                                    content="basic trigonometry visualizer/script interpeter ."
                                    tech={['C', 'WINAPI', 'flex/yacc']}/>
                            </Grid>

                        </Grid>
                    </Container>
                </section>

                <Divider />

                <section id="section-4">
                    <Container className={classes.cardGrid} maxWidth="md">
                        {/* End hero unit */}
                        <Grid container spacing={4}>

                            <Grid item xs={12} sm={6} md={6}>
                                <StyledCard
                                    classes={classes}
                                    img={img_dirwin}
                                    heading="dirwin.py"
                                    url="https://github.com/gbdu/dirwin.py"
                                    content="The window layout on the screen is stored in a buffer. The user can then move/focus windows through arrow keys and relative direction. Useful for EWHM-compatible window managers like Openbox."
                                    tech={['Python', 'EWHM', 'xprop', 'xdotool']}/>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <StyledCard
                                    classes={classes}
                                    img={img_monoterm}
                                    heading="monoterm.sh"
                                    url="http://frezr.com/monoterm.html"
                                    content="A script to keep only one terminal window open with tmux windows synchronized to Openbox virtual desktops."
                                    tech={['Bash', 'wmctrl', 'tmux']}/>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <StyledCard
                                    classes={classes}
                                    img={img_searcher}
                                    heading="firefox_searcher.sh"
                                    url="http://frezr.com/firefox-searcher.html"
                                    content="A script to search all Firefox visited sites (from sessionstore), opened tabs (mozrepl), and a saved bookmarks file. The results are filtered through fzf in a single tmux window."
                                    tech={['Python', 'fzf']}/>
                            </Grid>

                        </Grid>
                    </Container>

                </section>

            </main>
            <div className={classes.heroButtons}>
                <Grid container spacing={2} justify="center">
                    <Grid item>
                        <EmailButton email="ogrum@live.com"/>
                    </Grid>
                    {/* <Grid item>
                  <AnimatedModal name="Phone" content="Please contact me at 587-969-7422 during business hours." />
                </Grid> */}
                    <Grid item>
                        <ResumeButton name="Resume" url="https://frezr.com/resume.pdf" />
                    </Grid>
                    <Grid item>
                        <TopButton name="Top" url="/" />
                    </Grid>
                </Grid>
            </div>
            <footer className={classes.footer}>

                <Copyright />
            </footer>
            {/* End footer */}
        </React.Fragment>
    )
}
