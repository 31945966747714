import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import 'typeface-roboto';

const theme = createMuiTheme({
  typography: {
    
    "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  palette: {
    primary: {
      light: '#ff7ce8',
      main: '#BD0035',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff0000',
      main: '#ff4336',
      dark: '#ff000d',
      contrastText: '#000',
    },
  },
})

ReactDOM.render(
  <React.StrictMode>

<MuiThemeProvider theme={theme}>
    <App />
</MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
