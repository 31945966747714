import React, { Component } from 'react';
import Card from '@material-ui/core/Card';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';


class StyledCard extends Component {

  constructor(props) {
    super(props);
    this.state = { active: false }
    this.addActiveClass= this.addActiveClass.bind(this);
    
  }

  addActiveClass(index) {
    const activeClasses = [...this.state.activeClasses.slice(0, index), !this.state.activeClasses[index], this.state.activeClasses.slice(index + 1)].flat();
    this.setState({activeClasses});
  }

  state = {
    raised:false,
    active: false,
  }
  toggleClass() {
      const currentState = this.state.active;
      this.setState({ active: !currentState });
  };

  toggleRaised = () => this.setState({raised:!this.state.raised});
 
  onMouseOver = () => this.setState({ shadow: 3 });

  onMouseOut = () => this.setState({ shadow: 1 });

  renderTech(){
    if(this.props.tech){
      return (
      <Box fontWeight="fontWeightBold" fontFamily="Monospace">
        <br/>Technologies used:
      <ul>
        {this.props.tech.map(function(name,id){
           return <li>{name}</li>;
        })}
      </ul></Box>)
    }
    return null ;
  }
  render() {
    const { classes } = this.props;
    return (
      <Card classes={{ root: classes.card }}
        onMouseOver={this.toggleRaised}
        onMouseOut={this.toggleRaised}
        raised={this.state.raised}
      >
                          <CardMedia
                    className={classes.cardMedia}
                    //image="https://source.unsplash.com/random"
                    image={this.props.img}
                    
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      <a href={this.props.url}>
                        {this.props.heading}
                        </a>
                    </Typography>
                    <Typography>
                      {this.props.content}
                    </Typography>
                    {this.renderTech()}
                                          </CardContent>
                  {/* <CardActions>
                    <Button size="small" color="primary">
                      View
                    </Button>
                    <Button size="small" color="primary">
                      Edit
                    </Button>
                  </CardActions> */}
      </Card>
    );
  }
}
export default StyledCard;