import React,{Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import ScrollspyNav from "react-scrollspy-nav";
import Logo from "./Logo"


class MyNav extends Component {
    constructor(props){
        super(props);
        this.state = {
            class: ""
        }
        this.getWindowHeight = this.getWindowHeight.bind(this)
    }

  //use the lifecycle methods to trigger the getWindowHeight method.

  componentDidMount(){
    
    
    
    window.addEventListener('scroll', this.getWindowHeight);
    
    }

    componentWillUnmount(){
    
        window.removeEventListener('scroll', this.getWindowHeight);
    
    }

    //then create the method
    getWindowHeight = function() {

    const distanceY = window.pageYOffset ||
        document.documentElement.scrollTop
    const shrinkOn = 300;
    //Now In the condition change the state to smaller so if the condition is true it will change to smaller otherwise to default state
    if (distanceY > shrinkOn ) {
        this.setState({
        class: "smaller"
        })
    }
    else if (distanceY < 100) {
        this.setState({
            class: ""
            })
    }
    }

    
    render(){
       return (<AppBar position="sticky" className={this.props.classes.appbar}> 
       
        <div className="nav">
            
               <div style={{ float: 'left', fontSize: 24 }}>PioneerDesigns</div>
        
        <ScrollspyNav
                            scrollTargetIds={["section-1", "section-2", "section-3", "section-4"]}
                            offset={-150}
                            activeNavClass="is-active"
                            scrollDuration="500"
                            headerBackground="true"
                        > <ul>
                        <li><a href="#section-1">Contact</a></li>
                        <li><a href="#section-2">Websites</a></li>
                        <li><a href="#section-3">Research</a></li>
                        <li><a href="#section-4">Scripts</a></li>
                    </ul>
                </ScrollspyNav></div>
              </AppBar>
        
              )
    }
}

export default MyNav;
