import React,{Component} from 'react';
import Button from '@material-ui/core/Button';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import EmailButton from "./EmailButton"
import ResumeButton from './ResumeButton'

class ContactInfo extends Component {

    render(){
        return (
         <Container maxWidth="md">
         <div class="contactinfo">
         <br /><br /><br />
           
             Software engineer specializing in: 
            <ul>
              <li>Web development</li>
              <li>UI/UX design</li>  
            </ul>
           <div className={this.props.classes.heroButtons}>
             <Grid container spacing={2} justify="center">
               <Grid item>
                 <EmailButton email="ogrum@live.com"/>
               </Grid>
               {/* <Grid item>
                 <AnimatedModal name="Phone" content="Please contact me at 587-969-7422 during business hours." />
               </Grid> */}
               <Grid item>
                 <ResumeButton name="Resume" url="https://frezr.com/resume.pdf" />
               </Grid>
             </Grid>
           </div></div>
         </Container>)  
    }
}

export default ContactInfo;
